// 
// Widgets.scss
// 

.main-chart {
    .chart-border-left {
        border-left: 1.4px solid var(--#{$variable-prefix}border-color);
        padding: 2px 20px;

        &:last-child {
            margin-right: 0px;
        }
    }
}


/* Activity */

.activity-feed {
    list-style: none;

    .feed-item {
        position: relative;
        padding-bottom: 27px;
        padding-left: 16px;
        border-left: 2px solid var(--#{$variable-prefix}light);

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: -6px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 2px solid $primary;
            background-color: var(--#{$variable-prefix}custom-white);
        }

        &:last-child {
            border-color: transparent;
        }
    }
}